export default () => ({
  root: {
    color: '#A9B5BD',
    fontSize: 14,
    lineHeight: '17px',
    '& a': {
      color: '#A9B5BD',
      '&:hover': {
        color: '#3A9A3B',
      },
    },
  },
});
