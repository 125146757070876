import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import Button from 'components/Button';
import Search from './components/Search';
import PageHeader from 'components/PageHeader';
import PlusIcon from 'components/Button/icons/Plus';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import SublimitsTable from 'components/tables/SublimitsTable';
import { getSublimits, getSubsublimits, setFilters } from 'store/sublimits/actions';

const Sublimits: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchSublimits = () => dispatch(getSublimits());
  const fetchSubsublimits = () => dispatch(getSubsublimits());

  useEffect(() => {
    dispatch(setFilters('', '', false));
    fetchSublimits();
    fetchSubsublimits();
  }, []);

  const onAddButtonClick = () => {
    history.push(`${ROUTES.MEDAPP.SUBLIMITS.ROOT}/new`);
  };

  return (
    <>
      <PageBreadcrumbs
        data={[{ label: t('sublimits.home'), href: ROUTES.DASHBOARD.ROOT }, { label: t('sublimits.list') }]}
      />
      <PageHeader title={t('sublimits.sublimits_list')}>
        <Button onClick={onAddButtonClick} startIcon={<PlusIcon />}>
          {t('sublimits.add')}
        </Button>
      </PageHeader>
      <Search />
      <SublimitsTable />
    </>
  );
};

export default Sublimits;
