import React from 'react';
import { Rating as MuiRating } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import styles from './Rating.module.less';

interface IRating {
  value: number | null;
  setValue: (value: number | null) => void;
}

const Rating: React.FC<IRating> = ({ value, setValue }) => {
  return (
    <div className={styles.container}>
      <MuiRating
        value={value}
        size="large"
        classes={{ root: styles.ratingRoot }}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    </div>
  );
};

export default Rating;
