import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { ISubevents } from 'types/subevents';
import { AnyAction } from 'redux';

const initialState = {
  subeventsData: [],
  pending: false,
  error: null,
};

const setPending = (state: ISubevents, { payload }: AnyAction) => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
    error: payload.pending ? null : initialState.error,
  };
};

const setError = (state: ISubevents, { payload }: AnyAction) => {
  return {
    ...state,
    error: payload ? payload.error : initialState.error,
  };
};

const setSubevents = (state: ISubevents, { payload }: AnyAction) => {
  return {
    ...state,
    subeventsData: payload ? payload.subevents : initialState.subeventsData,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setSubevents,
    setPending,
    setError,
  },
});
