import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme';

export default makeStyles({
  root: {
    boxSizing: 'border-box',
  },
  medium: {
    width: 46,
    height: 46,
    borderRadius: '14px !important',
    padding: '13px !important',
  },
  large: {
    width: 56,
    height: 56,
    padding: '18px !important',
    borderRadius: '14px !important',
  },
  small: {
    width: 26,
    height: 26,
    padding: '6px !important',
    borderRadius: '8px !important',
  },
  smaller: {
    width: 22,
    height: 22,
    padding: '5px !important',
    borderRadius: '6px !important',
  },
  smallest: {
    width: 18,
    height: 18,
    padding: '3px !important',
    borderRadius: '5px !important',
  },
  iconSmall: {
    width: 14,
    height: 14,
    fontSize: 14,
  },
  white: {
    backgroundColor: theme.pallete.basic.white,
  },
  gray: {
    backgroundColor: theme.pallete.blueGray.lightest,
  },
  iconMedium: {
    width: 20,
    height: 20,
    fontSize: 20,
  },
  iconLarge: {
    width: 20,
    height: 20,
    fontSize: 20,
  },
  iconSmallest: {
    width: 12,
    height: 12,
    fontSize: 12,
  },
  iconSmaller: {
    width: 12,
    height: 12,
    fontSize: 12,
  },
  icon: {
    display: 'block',
    boxSizing: 'border-box',
  },
});
