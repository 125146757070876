import React from 'react';
import { moneyFormatter } from 'utils/helpers';

const InsuranceRisksColumns = (t: any, currency: string) => {
  return [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      width: 50,
    },
    {
      title: t('contract_details.insurance_risks_table'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('contract_details.sum_insured_table'),
      dataIndex: 'insuranceAmount',
      key: 'insuranceAmount',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.insurance_payment_table'),
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.franchise_table'),
      dataIndex: 'franchise',
      key: 'franchise',
      width: 200,
    },
  ];
};

export default InsuranceRisksColumns;
