import React, { RefObject } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export interface ScrollbarRef {
  scrollToBottom(): void;
  container: HTMLDivElement;
}

interface ScrollbarProps {
  id?: string;
  height?: number;
  innerRef?: RefObject<ScrollbarRef>;
}

const Scrollbar: React.FC<ScrollbarProps> = ({ id, height, innerRef, children }) => (
  <Scrollbars ref={innerRef} autoHeight autoHeightMin={height} autoHeightMax={height}>
    {children}
  </Scrollbars>
);

export default Scrollbar;
