import React from 'react';
import { Table as BaseTable, Empty } from 'antd';
import styles from './Table.module.less';
import { TableProps } from 'antd/lib/table';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

const Table = <T extends object>({ className, ...props }: TableProps<T>) => {
  const { t } = useTranslation();

  return (
    <BaseTable
      locale={{
        emptyText: <Empty description={t('no_data.no_data_antd')} image={Empty.PRESENTED_IMAGE_SIMPLE} />,
      }}
      className={cs(styles.root, className)}
      {...props}
    />
  );
};

export default Table;
