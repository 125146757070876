import React from 'react';
import Typography from 'components/Typography';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';
import { Chat } from 'types/chats';
import classNames from 'classnames';
import moment from 'moment';
import styles from './ChatsListItem.module.less';

interface ChatsListItemProps extends Chat {
  onClick(id: string): void;
  isOpened(id: string): boolean;
}

const ChatsListItem: React.FC<ChatsListItemProps> = ({
  isOpened,
  onClick,
  channelId,
  doctorType,
  consultationDate,
  duration,
}) => {
  const { t } = useTranslation();

  const startTime = new Date(consultationDate).getTime();

  return (
    <li
      onClick={() => onClick(channelId)}
      className={classNames(styles.chat, isOpened(channelId) && styles.opened)}
      role="presentation"
    >
      <Avatar title={doctorType} sidebar />
      <div className={styles.companionName}>
        <Typography type="p1">
          {doctorType}
          {' - '}
          {moment(new Date(consultationDate)).format('D MMMM')}
        </Typography>
        <span className={styles.subtitle}>
          {startTime > Date.now() && startTime + Number(duration) < Date.now() ? (
            t('chats.consultation_is_going')
          ) : (
            <>
              {t('chats.start_at')} {moment(new Date(startTime)).format('kk:mm')}
            </>
          )}
        </span>
      </div>
    </li>
  );
};

export default ChatsListItem;
