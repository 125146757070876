import { notification } from 'antd';
import React from 'react';
import Error from '../screens/Error';
import { withTranslation } from 'react-i18next';

class ErrorPageBoundary extends React.Component<{ children: any; t: any }, { error: any; info: any }> {
  constructor(props) {
    super(props);
    this.state = { error: null, info: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { t } = this.props;

    if (this.state.error) {
      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('error_page.no_connection'),
          description: t('error_page.check_network_connection'),
        });
      }
      return this.state.error.name === '404' ? (
        <Error code={this.state.error.name} name={t('error_page.error')} description={this.state.error.message} />
      ) : (
        <Error name={t('error_page.something_broke')} description={t('error_page.page_temporarily_down')} />
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorPageBoundary);
