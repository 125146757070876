import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import BasePaginationItem from '@material-ui/lab/PaginationItem';
import cs from 'classnames';
import omit from 'lodash/omit';

// Details https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/PaginationItem/PaginationItem.js
export interface IPaginationItemProps {
  classes: { [x: string]: any };
  className?: string;

  color?: 'default' | 'standard' | 'primary' | 'secondary'; // 'standard' The active color.
  component?: React.ReactNode; // The component used for the root node. Either a string to use a DOM element or a component.
  disabled?: boolean; //	false	If true, the item will be disabled.
  page: number; //		The current page number.
  selected?: boolean; //	false	If true the pagination item is selected.
  shape?: 'round' | 'rounded'; // 'round'The shape of the pagination item.
  size?: 'small' | 'medium' | 'large'; //'medium' The size of the pagination item.
  type?: 'default' | 'page' | 'first' | 'last' | 'next' | 'previous' | 'start-ellipsis' | 'end-ellipsis'; //	'page'	The type of pagination item.
  variant?: 'text' | 'outlined'; // 'text'
}

const PaginationItem: React.FC<IPaginationItemProps> = ({ type = 'default', classes, className, ...props }) => (
  <BasePaginationItem
    className={cs(className, type !== 'page' && classes[type])}
    classes={omit(classes, ['previous', 'next', 'first', 'last'])}
    //@ts-ignore
    type={type}
    {...props}
  />
);

export default withStyles(styles)(PaginationItem);
