import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    margin: 0,
    padding: 0,
  },
  heading: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '29px',
    color: '#363636',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '21px',
    color: '#363636',
  },
  p1: {
    fontSize: 15,
    lineHeight: '18px',
    color: '#363636',
  },
  p2: {
    fontSize: 14,
    lineHeight: '17px',
    color: '#363636',
  },
  p3: {
    fontSize: 13,
    lineHeight: '20px',
    color: '#363636',
  },
  caption: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '12px',
    color: '#363636',
  },
});
