export default () => ({
  root: {
    backgroundColor: 'transparent',
    color: '#363636',
    fontSize: 15,
    minWidth: 160,
    padding: '32px 20px 12px',
    margin: 0,
    lineHeight: '18px',
    '&:hover, &:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  // focused: {
  //   backgroundColor: 'transparent',
  // },
});
