import { IInfoPagination } from 'api/insuranceContracts';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

type Callback<T> = (data: any, IPagination?: IInfoPagination, t?: any) => Promise<T>;

export const useAsync = <Response, Error = any>(cb: Callback<Response>) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [value, setValue] = useState<Response | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const doFetch = useCallback(
    async (data?: any, pagination?: IInfoPagination, t?: any) => {
      setStatus('pending');
      setValue(null);
      setError(null);

      try {
        const response = await cb(data, pagination, t);
        if (history.location.pathname.split('/')[3] && history.location.pathname.split('/')[3].includes('create')) {
          setValue(null);
        } else {
          setValue(response);
        }
        setStatus('success');
      } catch (error) {
        setError(error);
        setStatus('error');
      }
    },
    [cb],
  );

  return { doFetch, status, value, error };
};
