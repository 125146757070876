import React, { useCallback } from 'react';
import { Button } from 'antd';
import { PrintIcon, ViewIcon } from 'components/Icons';
import { checkPermissions } from 'roles/userCan';
import styles from './TableTools.module.less';
import { contractPdfDownload } from 'store/contractDetails/actions';
import { redirectToContract } from '../../../../../constants/contractData';
import { useTranslation } from 'react-i18next';

interface ITools {
  id: number;
  data?: any;
  handleVisibleChange: (value: boolean) => void;
}

const TableTools = ({ id, handleVisibleChange, data }: ITools) => {
  const { t } = useTranslation();

  const handleDownload = useCallback(() => {
    contractPdfDownload(Number(id), t);
    handleVisibleChange(false);
  }, [id]);

  return (
    <div className={styles.container}>
      {checkPermissions('CONTRACT', 'READ') && (
        <Button data-id="view_contract" className={styles.button} type="link" onClick={() => redirectToContract(id)}>
          <ViewIcon />
          <span>{t('contract_details.review_button_sub_agreements')}</span>
        </Button>
      )}
      {checkPermissions('TERMINATION', 'PRINT') && (
        <Button className={styles.button} type="link" onClick={handleDownload}>
          <PrintIcon />
          <span>{t('contract_details.print_button_sub_agreements')}</span>
        </Button>
      )}
    </div>
  );
};

export default TableTools;
