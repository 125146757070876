import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const { t } = useTranslation();
  const error = new Error('404');
  error.name = '404';
  error.message = t('error_page.page_requested_not_found');
  throw error;
};

export default Page404;
