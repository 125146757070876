import React, { useCallback, useRef, useState } from 'react';
import { withStyles, InputBase } from '@material-ui/core';
import styles from './styles';
import { InputBaseProps } from '@material-ui/core/InputBase/InputBase';
import { ExpandIcon } from '../../../Icons';
import Modal from '../../../TextArea/Modal';

const TextAreaTrigger = ({ inputRef, label = '' }) => {
  const [value, setValue] = useState<string | undefined>(undefined);

  const handleClick = useCallback(() => {
    setValue(inputRef.current.value || inputRef.current.defaultValue || '');
  }, [inputRef]);

  const handleChange = useCallback(
    (value: string) => {
      setValue(undefined);
      inputRef.current.focus();
      inputRef.current.value = value;
      inputRef.current.blur();
    },
    [inputRef],
  );

  return (
    <>
      {typeof value !== 'undefined' && (
        <Modal
          visible={true}
          initialValue={value}
          onChange={handleChange}
          label={label}
          onClose={() => setValue(undefined)}
        />
      )}
      <ExpandIcon style={{ cursor: 'pointer' }} onClick={handleClick} />
    </>
  );
};

const Input: React.ComponentType<InputBaseProps> = React.forwardRef((props, baseRef) => {
  const ref = useRef(baseRef);
  return <InputBase inputRef={ref} {...props} endAdornment={<TextAreaTrigger inputRef={ref} />} />;
});

export default withStyles(styles)(Input);
