import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Event from '../Event';
import { IHistory } from 'types/appointments';
import { Filters } from 'components/Icons';

import { Wrapper, Header, HeaderName, SelectedDate, FiltersWrapper } from './Events.styled';

interface IEventsProps {
  events: IHistory[];
  viewDate: Date;
  onSubeventClick: (event: IHistory) => void;
}

const Events: React.FC<IEventsProps> = ({ events, viewDate, onSubeventClick }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <div>
          <HeaderName>{t('history.events')}</HeaderName>
          <SelectedDate>{moment(viewDate).format('DD MMMM')}</SelectedDate>
        </div>
        <FiltersWrapper>
          <Filters width={26} height={26} />
          {t('history.filters')}
        </FiltersWrapper>
      </Header>
      {events.map((event, i) => (
        <Event event={event} onSubeventClick={onSubeventClick} key={i} />
      ))}
    </Wrapper>
  );
};

export default Events;
