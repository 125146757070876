import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { IClaims } from 'types/claims';
import { AnyAction } from 'redux';

const initialState = {
  claimsData: [],
  claimStatus: 'REPORTED',
  pending: false,
  error: null,
};

const setPending = (state: IClaims, { payload }: AnyAction) => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
  };
};

const setError = (state: IClaims, { payload }: AnyAction) => {
  return {
    ...state,
    error: payload ? payload.error : initialState.error,
  };
};

const setClaims = (state: IClaims, { payload }: AnyAction) => {
  return {
    ...state,
    claimsData: payload ? payload.claims : initialState.claimsData,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setClaims,
    setPending,
    setError,
  },
});
