export default () => ({
  root: {
    fontSize: 18,
    position: 'absolute',
    right: 15,
    top: 24,
    bottom: 20,
    pointerEvents: 'none',
    fill: '#A9B5BD',
  },
});
