import React from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';

import closeIcon from 'assets/img/layout/greyClose.svg';
import styles from './ConfirmModal.module.less';
import { IConfirmModalProps } from 'components/ConfirmModal/ConfirmModal.interface';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = ({
  isModalOpen,
  onSubmitModal,
  onClose,
  children,
  closeText,
  submitText,
  onCloseBtn,
  disabled,
}: IConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isModalOpen}
      className={classNames(styles.container)}
      onOk={onSubmitModal}
      onCancel={onClose}
      width={500}
    >
      <div className={classNames(styles.modal_content)}>
        <div className={classNames(styles.close)}>
          <div className={classNames(styles.closeIcon)} onClick={onClose}>
            <img src={closeIcon} width="16" height="16" alt="close icon" />
          </div>
        </div>
        <div className="content">
          {children}
          <div className={classNames(styles.actions_btn)}>
            <button onClick={onCloseBtn ?? onClose}>{closeText ?? t('modal.no')}</button>
            <button onClick={onSubmitModal} disabled={disabled}>
              {submitText ?? t('modal.so')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
