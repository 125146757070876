export default () => ({
  root: {
    position: 'relative',
    minWidth: 206,
    '@global': {
      'label + .MuiInput-formControl': {
        margin: '0 !important',
      },
    },
  },
  clearable: {
    '@global': {
      '.MuiSelect-select.MuiSelect-select': {
        paddingRight: 24 + 42,
        minWidth: 160 - 42,
      },
    },
  },
  clearButton: {
    position: 'absolute',
    top: 22,
    right: 38,
  },
});
