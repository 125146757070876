import { makeStyles } from '@material-ui/core/styles';

export interface IUseMenuStylesProps {
  color?: string;
  offset?: number;
}

export default makeStyles({
  paper: {
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.12)',
    borderRadius: '0 0 14px 14px',
    clipPath: 'inset(0px -50px -50px)',
    marginTop: (props: IUseMenuStylesProps) => (typeof props.offset === 'undefined' ? -15 : -props.offset - 1),
  },
  list: {
    backgroundColor: (props: IUseMenuStylesProps) => props.color || 'white',
    padding: (props: IUseMenuStylesProps) =>
      typeof props.offset === 'undefined' ? '14px 0 0 0' : props.offset + 'px 0 0 0',
  },
});
