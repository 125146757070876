import React from 'react';
import { useLocation } from 'react-router-dom';

import history from 'routes/history';

import { ROUTES } from '../../../constants/routes';
import cat from 'assets/img/layout/cat.svg';
import dog from 'assets/img/layout/dog.svg';
import styles from './styles.module.less';
import { getErrors } from '../paymentErrors';

interface IProps {
  orderData: any;
}

export const DeniedPaymentPage: React.FC<IProps> = ({ orderData }) => {
  const location = useLocation();
  const locationArr = location.pathname.split('/');

  const goBack = () => {
    history.push(ROUTES.CLIENT_SIGN.replace(':id', locationArr[3]));
  };

  const getErrorsList = () => {
    const errors = JSON.parse(orderData.data.errror);
    return <li>{getErrors(errors.code)}</li>;
  };

  return (
    <div className={styles.DeniedPage__Container}>
      <div className={styles.DeniedPage__Header}>
        <span>Щось пішло не так...</span>
        <span>Чому не проходить оплата банківської карти?</span>
      </div>
      <div className={styles.DeniedPage__Line} />
      <div className={styles.DeniedPage__Body}>
        <div className={styles.img}>
          <img src={cat} alt={'cat'} />
        </div>
        <div className={styles.DeniedPage__List}>
          <ul>{getErrorsList()}</ul>
        </div>
        <div className={styles.img}>
          <img src={dog} alt={'dog'} />
        </div>
      </div>
      <div className={styles.DeniedPage__Line} />
      <div className={styles.DeniedPage__Actions}>
        <button onClick={goBack}>Спробувати ще раз</button>
      </div>
    </div>
  );
};
