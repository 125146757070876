import React from 'react';
import { Tooltip as NativeTooltip } from 'antd';

import * as Styled from './Legend.styled';
import styles from './Tooltip.module.less';
import Tooltip from 'components/Dashboard/Charts/Tooltip';
import { ILegendProps } from 'components/Dashboard/Charts/Legend/Legend.interface';

const Legend = ({ data, partFromAll, ...props }: ILegendProps) => (
  <Styled.Items {...props}>
    {data.map(({ id, label, color, value, previousValue, sum, fullValue }) => (
      <NativeTooltip
        key={id}
        placement="top"
        overlayClassName={styles.tooltip}
        overlay={
          <Tooltip
            id={id}
            label={label}
            value={value}
            partFromAll={partFromAll}
            fullValue={fullValue}
            previousValue={previousValue}
            sum={sum}
          />
        }
      >
        <Styled.Item>
          <Styled.Color color={color} />
          <Styled.Label>{label}</Styled.Label>
        </Styled.Item>
      </NativeTooltip>
    ))}
  </Styled.Items>
);

export default Legend;
