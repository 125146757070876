import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme';

export default makeStyles({
  root: {
    color: theme.pallete.table.red.main,
    margin: '6px 16px 0',
    fontSize: 10,
    lineHeight: '12px',
  },
});
