import seller from './userTypes/role_seller';
import managers from './userTypes/role_managers';
import admins from './userTypes/role_admins';
import clients from './userTypes/role_clients';

interface IRule {
  [x: string]: any;

  HELP: any;
  SETTINGS: any;
}

interface IRules {
  [role: string]: IRule;
}

export const rules: IRules = {
  ...clients,
  ...seller,
  ...managers,
  ...admins,
};

export default rules;
