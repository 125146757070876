import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import config from 'config';

const initialState: any = {
  data: [],
  total: 0,
  pending: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  serviceTypeServices: [],
};

const setMedApp = (state: any, { payload, pending }: AnyAction): any => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    pending,
  };
};

const setPagination = (state: any, { payload }: AnyAction): any => {
  return {
    ...state,
    pagination: payload,
  };
};

const serviceTypeServices = (state: any, { payload }: AnyAction): any => {
  return {
    ...state,
    serviceTypeServices: payload?.resultList,
  };
};

const removeInstitution = (state: any, { payload, pending }: AnyAction): any => {
  return {
    ...state,
    pending,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setMedApp,
    setPagination,
    serviceTypeServices,
    removeInstitution,
  },
});
