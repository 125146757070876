import { withStyles, Box, Typography } from '@material-ui/core';
import BasePagination from 'components/Pagination';

export const Root = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    borderRadius: 12,
  },
})(Box);

export const Left = withStyles({
  root: {
    display: 'flex',
  },
})(Box);

export const ItemContainer = withStyles({
  root: {
    padding: '8px 16px 9px',
  },
})(Box);

export const LeftItem = withStyles({
  root: {
    minWidth: 164,
    borderRight: '1px solid #eaeced',
  },
})(Box);

export const Right = withStyles({
  root: {},
})(Box);

export const Pagination = withStyles({
  root: {
    margin: '14px 16px 15px',
  },
})(BasePagination);

export const Label = withStyles({
  body1: {
    fontSize: 13,
    lineHeight: '16px',
    color: '#A9B5BD',
    marginBottom: 4,
  },
})(Typography);

export const Value = withStyles({
  body1: {
    fontSize: 15,
    lineHeight: '18px',
    color: '#363636',
    display: 'inline-block',
  },
})(Typography);
