import { withStyles, IconButton as IconButtonBase } from '@material-ui/core';

export const IconButton = withStyles({
  root: {
    color: '#A9B5BD',
    '&:hover': {
      backgroundColor: '#F6F7FA',
      color: '#3A9A3B',
    },
    '&:disabled': {
      backgroundColor: '#FFFFFF',
      color: '#B2D6B2',
    },
  },
  label: {
    display: 'block',
  },
})(IconButtonBase);
