export default () => ({
  root: {
    position: 'relative',
    background: '#FFFFFF',
  },
  table: {
    '& .ant-table-body-inner': {
      overflow: 'auto',
    },
  },
  reset: {},
  footer: {},
});
