import React, { HTMLAttributes } from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    color: '#A9B5BD',
    textAlign: 'center',
    display: 'inline-block',
  },
  icon: {
    marginBottom: 10,
  },
  label: {
    fontSize: 14,
    lineHeight: '17px',
    display: 'block',
  },
});

export interface INoDataProps extends HTMLAttributes<HTMLDivElement> {}

const NoData: React.FC<INoDataProps> = ({ className, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={cs(classes.root, className)} {...props}>
      <SvgIcon
        className={classes.icon}
        width={30}
        height={23}
        viewBox="0 0 30 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.48202 0H6.87353C7.22023 0 7.55595 0.121701 7.82229 0.34393L9.53007 1.76886C9.59666 1.82441 9.68059 1.85484 9.76726 1.85484H25.1943C26.0128 1.85484 26.6763 2.51919 26.6763 3.33871V6.67742C26.6763 6.8823 26.8422 7.04839 27.0468 7.04839H28.5289C29.3414 7.04839 30 7.70786 30 8.52137C30 8.63368 29.9872 8.74562 29.9618 8.85501L26.9429 21.8522C26.7868 22.5243 26.1886 23 25.4994 23H1.48202C0.663522 23 0 22.3356 0 21.5161V1.48387C0 0.664352 0.663522 0 1.48202 0ZM25.1943 6.67742V3.70968C25.1943 3.5048 25.0284 3.33871 24.8238 3.33871H9.63312C9.28642 3.33871 8.9507 3.21701 8.68436 2.99478L6.97658 1.56985C6.90999 1.5143 6.82606 1.48387 6.73939 1.48387H1.85252V1.48389C1.64791 1.48389 1.48203 1.64997 1.48202 1.85484L1.48103 17.3281C1.48103 17.3896 1.53079 17.4394 1.59218 17.4394C1.64394 17.4394 1.68885 17.4037 1.70049 17.3532L3.81045 8.1986C3.96563 7.52532 4.56441 7.04839 5.25452 7.04839H24.8238C25.0284 7.04839 25.1943 6.8823 25.1943 6.67742ZM2.68877 21.5161H25.2052C25.3775 21.5161 25.527 21.3972 25.5661 21.2292L28.4095 8.98723C28.4558 8.78767 28.3318 8.58828 28.1325 8.54187C28.105 8.53546 28.0769 8.53223 28.0486 8.53223L5.80544 8.53078C5.46036 8.53076 5.16095 8.76923 5.08335 9.10589L2.32775 21.0617C2.28174 21.2614 2.40608 21.4606 2.60546 21.5066C2.63278 21.5129 2.66073 21.5161 2.68877 21.5161Z"
          fill="currentColor"
        />
      </SvgIcon>
      <span className={classes.label}>{t('no_data.no_data')}</span>
    </div>
  );
};

export default NoData;
