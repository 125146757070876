import moment from 'moment';
import React from 'react';

interface IDateLabel {
  children: string;
}

const DateLabel = ({ children }: IDateLabel) => {
  if (children === '' || typeof children !== 'string') {
    return <> - </>;
  }

  return <>{moment(children).format('DD.MM.YYYY')}</>;
};

export default DateLabel;
