import styled from 'styled-components';

export const Wrapper = styled.div`
  /* padding: 16px; */
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const HeaderName = styled.h5`
  color: #363636;
  display: inline-block;
  font-family: 'SF UI Display';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-right: 15px;
`;

export const SelectedDate = styled.span`
  color: #a9b5bd;
  font-family: 'SF UI Display';
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
`;

export const FiltersWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;

  svg {
    margin-right: 10px;
  }
`;
