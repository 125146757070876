import React, { useEffect } from 'react';
import columns from './columns';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducer';
import { setMedApp, setPagination } from 'store/medApp/actions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tablex from './Tablex';

const TableLight = styled.div`
  padding-top: 20px;
  & .ant-table-thead tr > th {
    background: #ffffff !important;
  }
`;
interface IMedAppTable {
  leadToCompanyConfiguration: (id: string | number) => void;
}

const MedAppTable: React.FC<IMedAppTable> = ({ leadToCompanyConfiguration }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, current, perPage, total, pending } = useSelector(
    ({
      medApp: {
        pagination: { current, perPage },
        data,
        total,
        pending,
      },
    }: AppState) => ({ data, current, perPage, total, pending }),
  );

  const pagination = {
    page: current,
    size: perPage,
    total: total || 0,
  };

  const onPageChange = (page: number, pageSize?: number): void => {
    dispatch(setPagination(page, pageSize || 0));
    dispatch(setMedApp());
  };

  useEffect(() => {
    dispatch(setMedApp());
  }, []);

  return (
    <TableLight>
      <Tablex
        columns={columns(t)}
        paginations={pagination}
        data={data}
        leadToCompanyConfiguration={leadToCompanyConfiguration}
        onPageChange={onPageChange}
        loading={pending}
      />
    </TableLight>
  );
};

export default MedAppTable;
