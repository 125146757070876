import React from 'react';
import cls from 'classnames';

import styles from './PageHeader.module.less';

interface IHeaderProps {
  title?: string;
  children?: React.ReactNode;
  dataId?: string;
  withoutSpacing?: boolean;
}

const PageHeader = ({ title, children, dataId, withoutSpacing }: IHeaderProps) => (
  <div data-id={dataId} className={cls(styles.titleContainer, { [styles.containerWithoutSpacing]: withoutSpacing })}>
    {title && <div className={styles.title}>{title}</div>}
    {children}
  </div>
);

export default PageHeader;
