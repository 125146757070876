import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { IInstitutions } from 'types/institutions';
import { AnyAction } from 'redux';
import config from 'config';

const initialState = {
  servicesData: {},
  serviceById: {},
  classes: [],
  classesByInsuranceProgramId: [],
  brands: [],
  brandsByInsuranceProgramId: [],
  institutions: {},
  insuranceProgramInstitutions: [],
  pending: false,
  error: null,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'serviceName',
    direction: 'ASC',
  },
  filters: {
    serviceName: '',
    serviceTypeName: '',
  },
};

const setPending = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
    error: payload.pending ? null : initialState.error,
  };
};

const setError = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    error: payload ? payload.error : initialState.error,
  };
};

const setServices = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    servicesData: payload ? payload.services : initialState.servicesData,
  };
};

const setServiceById = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    serviceById: payload ? payload.service : initialState.serviceById,
  };
};

const setClasses = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    classes: payload ? payload.classes : initialState.classes,
  };
};

const setInstitutionsClassesByInsuranceProgramId = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    classesByInsuranceProgramId: payload
      ? payload.classesByInsuranceProgramId
      : initialState.classesByInsuranceProgramId,
  };
};

const setBrands = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    brands: payload ? payload.brands : initialState.brands,
  };
};

const setInstitutionsBrandsByInsuranceProgramId = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    brandsByInsuranceProgramId: payload ? payload.brandsByInsuranceProgramId : initialState.brandsByInsuranceProgramId,
  };
};

const setInstitutions = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    institutions: payload ? payload.institutions.data : initialState.institutions,
  };
};

const setInsuranceProgramInstitutions = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    insuranceProgramInstitutions: payload
      ? payload.insuranceProgramInstitutions
      : initialState.insuranceProgramInstitutions,
  };
};

const setSorting = (state: IInstitutions, { payload }: AnyAction) => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const setPagination = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    pagination: payload,
  };
};

const setFilters = (state: IInstitutions, { payload }: AnyAction) => {
  return {
    ...state,
    filters: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setServices,
    setServiceById,
    setClasses,
    setInstitutionsClassesByInsuranceProgramId,
    setBrands,
    setInstitutionsBrandsByInsuranceProgramId,
    setInstitutions,
    setInsuranceProgramInstitutions,
    setSorting,
    setPagination,
    setFilters,
    setPending,
    setError,
  },
});
