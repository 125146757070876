interface IErrors {
  [index: string]: any;
}

export const adapter: IErrors = {
  departmentNumber: {
    name: 'departmentNumber',
    REQUIRED: 'Введіть префікс',
    NOT_UNIQUE: 'Такий префікс вже існує',
  },
  partnerType: {
    name: 'partnerType',
    REQUIRED: 'Будь ласка, виберіть тип партнеру',
  },
  partner: {
    name: 'partner.id',
    REQUIRED: 'Будь ласка, виберіть партнера',
  },
  region: {
    name: 'partnerDepartmentRegion',
    REQUIRED: 'Будь ласка, введіть підрозділ',
  },
  type: {
    name: 'type',
    REQUIRED: 'Будь ласка, виберіть тип підрозділу',
  },
  name: {
    name: 'name',
    REQUIRED: 'Введіть найменування підрозділу',
    NOT_UNIQUE: "Таке ім'я вже існує",
  },
  'address.location': {
    name: 'regionAddress',
    REQUIRED: 'Введіть місто, область чи район',
  },
  'address.street': {
    name: 'address.street',
    REQUIRED: 'Введіть коректно адресу',
  },
  directorFullName: {
    name: 'directorFullName',
    REQUIRED: 'Будь ласка, введіть дані',
  },
  phoneNumber: {
    name: 'phoneNumber',
    REQUIRED: 'Будь ласка, введіть номер телефону',
  },
  email: {
    name: 'email',
    REQUIRED: 'Будь ласка, введіть e-mail',
  },
};
