export interface RawMessage {
  uuid: string;
  message:
    | string
    | {
        file: {
          name: string;
          id: string;
        };
      };
  timetoken: number;
}

export enum MeetingType {
  Video = 'video',
  Audio = 'audio',
}

export interface RegularMessage {
  type: 'plaintext';
  body: string;
}

export interface Attachment {
  title: string;
  url: string;
}

export interface FileMessage extends Attachment {
  type: 'file';
}

export type Message = (RegularMessage | FileMessage) & {
  publisher: string;
  timetoken: number;
};

export interface Chat {
  doctorType: string;
  consultationDate: string;
  duration: string;
  consultationDuration: string;
  channelId: string;
  user?: {
    lastName: string;
    firstName: string;
    middleName: string;
  };
  // Поля ниже возвращаются только в день консультации
  // https://confluence.chisw.com/pages/viewpage.action?pageId=45651047
  sessionId?: string;
  token?: string;
}

export enum Device {
  Microphone,
  Camera,
}
