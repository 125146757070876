import React from 'react';
import cls from 'classnames';
import styles from './Status.module.less';

interface StatusProps {
  status: string;
  translation: string;
}

const Status: React.FC<StatusProps> = ({ status, translation }) => {
  const activeStatus = status?.toLocaleLowerCase() || '';

  return <div className={cls(styles.statusContainer, styles[activeStatus])}>{translation}</div>;
};

export default Status;
