import React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import history from 'routes/history';
import styles from './Buttons.module.less';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface ButtonsProps {
  onSubmit(): void;
  className?: string;
  onCancel?: () => void;
}

const Buttons: React.FC<ButtonsProps> = ({ onSubmit, className, onCancel }) => {
  const { t } = useTranslation();

  const handleGoBack = () => {
    if (onCancel) {
      return onCancel();
    }
    history.goBack();
  };

  return (
    <div className={classNames(styles.buttonsContainer, className)}>
      <Grid container spacing={2} className={styles.buttons}>
        <Grid item xs={6}>
          <Button fullWidth onClick={handleGoBack}>
            {t('appointments.cancel')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button color="primary" fullWidth onClick={onSubmit}>
            {t('appointments.confirm')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Buttons;
