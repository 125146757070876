import React from 'react';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { IconButtonTypeMap } from '@material-ui/core/IconButton/IconButton';
import useStyles from './useStyles';
import S from 'string';
import * as Styled from './styled';
import cs from 'classnames';

export interface IIconButtonProps extends ExtendButtonBase<IconButtonTypeMap> {
  size?: 'medium' | 'large' | 'small' | 'smaller' | 'smallest';
  className?: string;
  variant?: 'white' | 'gray';
}

const IconButton = React.forwardRef<React.FC<IIconButtonProps>, any>(
  ({ className, size = 'medium', variant = 'white', children, ...props }, ref) => {
    const classes = useStyles();
    const sizeClassName = S(size).capitalize();

    return (
      <Styled.IconButton {...props} className={cs(classes[size], classes[variant], className)} ref={ref}>
        {React.cloneElement(children, {
          width: null,
          height: null,
          className: cs(classes.icon, classes['icon' + sizeClassName]),
        })}
      </Styled.IconButton>
    );
  },
);

export default IconButton;
