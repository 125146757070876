export default () => ({
  root: {
    pointerEvents: 'none',
    color: '#A9B5BD',
    fontSize: 15,
    lineHeight: '16px',
    left: 20,
    transform: 'translate(0, 23px)',
    userSelect: 'none',
    cursor: 'pointer',
  },
  focused: {
    color: '#3A9A3B !important',
  },
  shrink: {
    top: 13,
    left: 20,
    transform: 'scale(0.86666666666)',
  },
});
