import React from 'react';
import useStyles from './useStyles';
import cs from 'classnames';

export interface ITypographyProps {
  type?: 'heading' | 'title' | 'p1' | 'p2' | 'p3' | 'caption';
  className?: string;
}

const Typography: React.FC<ITypographyProps> = ({ type = 'p2', children, className, ...props }) => {
  const classes = useStyles();

  switch (type) {
    case 'heading':
      return (
        <h1 className={cs(classes.root, classes.heading, className)} {...props}>
          {children}
        </h1>
      );
    case 'title':
      return (
        <h2 className={cs(classes.root, classes.title, className)} {...props}>
          {children}
        </h2>
      );
    case 'p1':
      return (
        <p className={cs(classes.root, classes.p1, className)} {...props}>
          {children}
        </p>
      );
    case 'p2':
      return (
        <p className={cs(classes.root, classes.p2, className)} {...props}>
          {children}
        </p>
      );
    case 'p3':
      return (
        <p className={cs(classes.root, classes.p3, className)} {...props}>
          {children}
        </p>
      );
    case 'caption':
      return (
        <p className={cs(classes.root, classes.caption, className)} {...props}>
          {children}
        </p>
      );
  }
};

export default Typography;
