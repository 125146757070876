import React from 'react';
import CountDown, { zeroPad } from 'react-countdown';

export default React.memo(({ delay, onComplete, className, ...rest }: any) => {
  const renderer = ({ minutes, seconds }: any) => (
    <span className={className}>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  return <CountDown date={Date.now() + delay} renderer={renderer} onComplete={onComplete} {...rest} />;
});
