import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSpinner from '../uikit/LoadingSpinner';

const useStyles = makeStyles({
  root: {
    width: 'calc(100% - 60px)',
    height: 'calc(100% - 60px)',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PageLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoadingSpinner size={64} disableShrink={true} />
    </div>
  );
};

export default PageLoading;
