export default () => ({
  icon: {
    fill: '#6C6D70',
    display: 'block',
    width: 12,
    height: 12,
  },
  iconContainer: {
    padding: 5,
    width: 22,
    height: 22,
    background: '#F6F8F8',
    borderRadius: 6,
    display: 'inline-block',
    textAlign: 'center' as 'center',
  },
});
