import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { ProductsList } from 'types/productsList';
import { TYPES } from './actions';
import { sortCustomArray } from 'utils/helpers';

// TODO Add error handler
const initialState = {
  products: [],
  pending: false,
  insObjects: [],
  contractTypeProducts: [],
};

const setProducts = (state: ProductsList, { payload }: AnyAction): ProductsList => {
  return {
    ...state,
    products: sortCustomArray(payload.data),
    pending: false,
  };
};

const setPending = (state: ProductsList, { pending }: AnyAction): ProductsList => {
  return { ...state, pending };
};

const setInsObjects = (state: any, { payload }: AnyAction) => {
  return {
    ...state,
    insObjects: payload,
  };
};

const setContractTypeProducts = (state: ProductsList, { payload }: AnyAction): ProductsList => {
  return {
    ...state,
    contractTypeProducts: sortCustomArray(payload.data),
    pending: false,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setProducts,
    setPending,
    setInsObjects,
    setContractTypeProducts,
  },
});
