import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import Button from 'components/Button';
import Search from './components/Search';
import PageHeader from 'components/PageHeader';
import PlusIcon from 'components/Button/icons/Plus';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import ServicesTable from 'components/tables/ServicesTable';
import { getServices, setFilters } from 'store/institutions/actions';

const Services: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchServices = () => dispatch(getServices());

  useEffect(() => {
    dispatch(setFilters('', ''));
    fetchServices();
  }, []);

  const onAddButtonClick = () => {
    history.push(`${ROUTES.MEDAPP.SERVICES.ROOT}/new`);
  };

  return (
    <>
      <PageBreadcrumbs
        data={[{ label: t('services.home'), href: ROUTES.DASHBOARD.ROOT }, { label: t('services.list') }]}
      />
      <PageHeader title={t('services.list')}>
        <Button onClick={onAddButtonClick} startIcon={<PlusIcon />}>
          {t('services.add_service')}
        </Button>
      </PageHeader>
      <Search />
      <ServicesTable />
    </>
  );
};

export default Services;
