export default () => ({
  root: {},
  page: {
    borderRadius: '8px',
    height: 26,
    minWidth: 26,
    padding: 4,
    fontSize: 15,
    lineHeight: '18px',
    color: '#6C6D70',
  },
  selected: {
    color: '#FFFFFF',
    backgroundColor: '#3A9A3B !important',
  },
  previous: {
    padding: 0,
    background: '#eef0f2',
    color: '#A9B5BD',
    margin: '0 20px 0 0',
  },
  next: {
    padding: 0,
    background: '#eef0f2',
    color: '#A9B5BD',
    margin: '0 0 0 20px',
  },
  first: {
    padding: 0,
    background: '#eef0f2',
    color: '#A9B5BD',
    margin: '0 10px 0 0',
  },
  last: {
    padding: 0,
    background: '#eef0f2',
    color: '#A9B5BD',
    margin: '0 0 0 10px',
  },
});
