import { Checkbox, withStyles } from '@material-ui/core';

export default withStyles({
  root: {
    width: 16,
    height: 16,
    '&$checked': {
      color: '#3A9A3B',
    },
  },
  icon: {
    backgroundColor: '#EEEFF4',
    borderRadius: 5,
  },
  checked: {
    color: '#3A9A3B',
  },
  colorSecondary: {
    color: '#EEEFF4',
  },
})(Checkbox);
