import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Chat from '../Chats/components/Chat';
import Meeting from '../Chats/components/Meeting';
import { useChats } from 'api/chats';
import { MeetingType } from 'types/chats';
import styles from './VideoChatForDoctor.module.less';

import { withPubNub } from 'api/pubNub';

const VideoChatForDoctor: React.FC = () => {
  const { location } = useHistory();

  const { opened, onOpen } = useChats(location?.state?.channelId);

  useEffect(() => {
    onOpen(location.state);
  }, []);

  const [meeting, setMeeting] = useState<MeetingType>();
  const [startMeeting, setStartMeeting] = useState<boolean>(true);

  const stopMeeting = () => {
    setStartMeeting(false);
    setMeeting(void 0);
  };

  return (
    <div className={styles.container}>
      <Grid className={styles.outer} container spacing={0}>
        {opened && meeting && (
          <Grid item xs={9} classes={{ item: styles.item }}>
            <Meeting opened={opened} type={meeting} userName={opened.user} onClose={stopMeeting} />
          </Grid>
        )}
        <Grid item xs={meeting ? 3 : 12} classes={{ item: styles.item }}>
          <Chat chat={opened} meeting={!!meeting} onStartMeeting={setMeeting} startMeeting={startMeeting} />
        </Grid>
      </Grid>
    </div>
  );
};

export default withPubNub(VideoChatForDoctor);
