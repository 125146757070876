import { IInsuredEvent, InsuredEvent } from './InsuredEvent';

export interface IPaymentSumInfo {
  currentLiabilitySum?: null | number;
  insuranceSum?: null | number;
  depreciation?: null | number;
  depreciationRate?: null | number;
  paymentsSum?: null | number;
  expertSum?: null | number;
  documents?: any[];
  additionalDeductions?: IAdditionalDeduction[];
  franchise?: null | number;
  franchisePercent?: null | number;
  paymentSum?: null | number;
}

export class PaymentSumInfo implements IPaymentSumInfo {
  currentLiabilitySum = null;
  insuranceSum = null;
  depreciation = null;
  depreciationRate = null;
  paymentsSum = null;
  expertSum = null;
  documents = [];
  additionalDeductions = [];
  franchise = null;
  franchisePercent = null;
  paymentSum = null;
}

export interface IAdditionalDeduction {
  value: number;
  name: string;
  id: null | number;
}

export interface IInsuredCase extends IInsuredEvent {
  paymentSumInfo: IPaymentSumInfo;
}

export class InsuredCase extends InsuredEvent implements IInsuredCase {
  paymentSumInfo = new PaymentSumInfo();
  objectRecipient = null;
  paymentRecipient = null;
}
