import React, { FunctionComponent } from 'react';
import { Modal, Spin } from 'antd';
import sign from '../../SignUp/SignUp.module.less';

type TModal = {
  isOpen: boolean;
};

const SpinnerModal: FunctionComponent<TModal> = ({ isOpen }) => (
  <Modal visible={isOpen} className={sign.signup_modal} centered maskClosable={false} closable={false} footer={null}>
    <div className={sign.signup_spinner}>
      <Spin size="large" />
    </div>
  </Modal>
);

export default SpinnerModal;
