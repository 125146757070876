import { IExpressPayment } from 'types/expressPayment';
import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';

export const initialState: IExpressPayment = {
  expressPaymentData: {
    contractNumber: '',
    contractDate: '',
    claimNumber: '',
    claimDate: '',
    insuranceObject: '',
    risk: '',
    paymentRecipient: {
      id: null,
      type: '',
      counterpartyType: '',
      phoneNumber: '',
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: '',
      locality: '',
      street: '',
      code: '',
      accountType: '',
      accountNumber: '',
      // bankName: '',
    },
    paymentSum: '',
  },
  paymentsSystems: [],
  smsCode: '',
  loading: false,
  claimId: null,
};

const expressPaymentState = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...state,
    loading: payload.loading,
  };
};

const expressPaymentTemplate = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...state,
    // expressPaymentData: payload.expressPaymentData || state.expressPaymentData,
    claimId: payload.claimId || state.claimId,
    expressPaymentData: {
      ...payload.expressPaymentData,
      paymentRecipient: {
        ...payload.expressPaymentData.paymentRecipient,
        id: payload.claimId,
      },
    },
  };
};

const getPaymentsSystems = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...state,
    paymentsSystems: payload,
  };
};

const expressPaymentFields = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...state,
    expressPaymentData: {
      ...state.expressPaymentData,
      paymentRecipient: {
        ...state.expressPaymentData.paymentRecipient,
        code: payload.code || state.expressPaymentData.paymentRecipient.code,
        accountNumber: payload.accountNumber || state.expressPaymentData.paymentRecipient.accountNumber,
        // bankName: payload.bankName || state.expressPaymentData.paymentRecipient.bankName,
      },
    },
  };
};

const setExpressPaymentFields = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  const { smsCode, ...rest } = payload;
  return {
    ...state,
    smsCode: smsCode || state.smsCode,
    expressPaymentData: {
      ...state.expressPaymentData,
      paymentRecipient: {
        ...state.expressPaymentData.paymentRecipient,
        ...rest,
      },
    },
  };
};

const clearExpressPayment = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...initialState,
  };
};

const setAccountType = (state: IExpressPayment, { payload }: AnyAction): IExpressPayment => {
  return {
    ...state,
    expressPaymentData: {
      ...state.expressPaymentData,
      paymentRecipient: {
        ...state.expressPaymentData.paymentRecipient,
        accountType: payload.accountType || state.expressPaymentData.paymentRecipient.accountType,
      },
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    expressPaymentState,
    expressPaymentTemplate,
    expressPaymentFields,
    setExpressPaymentFields,
    clearExpressPayment,
    setAccountType,
    getPaymentsSystems,
  },
});
