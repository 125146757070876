import { moneyFormatter } from 'utils/helpers';

interface InsuranceRisksData {
  id: number;
  franchise: number;
  franchiseValueType: string;
  insuranceAmount: number;
  name: string;
  paymentAmount: number;
  riskNumber: number;
}

export const formatInsuranceRisks = (data: [InsuranceRisksData]) => {
  return (
    data &&
    data.length &&
    data.map((el, index) => ({
      ...el,
      index: index + 1,
      paymentAmount: `${moneyFormatter(el.paymentAmount)}`,
      insuranceAmount: `${moneyFormatter(el.insuranceAmount)}`,
    }))
  );
};
