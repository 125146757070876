import { Input as BaseInput, withStyles } from '@material-ui/core';

export const Input = withStyles({
  root: {
    background: '#EEEFF4',
    borderRadius: 14,
    padding: '7px 16px',
    fontSize: 15,
    lineHeight: '22px',
    color: '#363636',
  },
})(BaseInput);
