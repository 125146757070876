import styled from 'styled-components';

export const Color = styled.div`
  display: inline-block;
  border-radius: 4px;
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
`;

export const Label = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #a9b5bd;
  margin-left: 6px;
`;

export const Item = styled.div`
  display: flex;
  margin: 7px;
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -7px;
  justify-content: space-between;
`;
