import React from 'react';
import logo from 'assets/img/logo.png';
import logoCHI from 'assets/img/logo_chi.svg';
import styles from '../../auth.module.less';

const HeaderAuth = () => (
  <div className={styles.logos}>
    <div className={styles.logo}>
      <img src={logo} alt="Logo" />
    </div>
    <img src={logoCHI} alt="Logo CHI" />
  </div>
);

export default HeaderAuth;
