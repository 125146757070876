import { DependencyList, useEffect } from 'react';

export const useOnResize = (handlerFn: () => void, deps = <DependencyList>[], didMount = true) => {
  useEffect(() => {
    if (didMount) {
      handlerFn();
    }
    window.addEventListener('resize', handlerFn);
    return () => {
      window.removeEventListener('resize', handlerFn);
    };
  }, deps);

  return null;
};
