import { DaysStatisticsDto } from 'types/dto/contracts-service';

import { Data } from './ByMonthChart/ByMonthChart';

export const transformDataForChart = (data: DaysStatisticsDto[]): Data[] => {
  return data.reduce((acc: Data[], el) => {
    acc.push(
      {
        xField: el.signDate ?? '',
        yField: el.contractPaymentSum ?? 0,
        seriesField: 'seriesField_1',
        raw: { ...el, seriesField_1: el.contractPaymentSum ?? 0, seriesField_2: el.claimPaymentSum ?? 0 },
      },
      {
        xField: el.signDate ?? '',
        yField: el.claimPaymentSum ?? 0,
        seriesField: 'seriesField_2',
        raw: { ...el, seriesField_1: el.contractPaymentSum ?? 0, seriesField_2: el.claimPaymentSum ?? 0 },
      },
    );

    return acc;
  }, []);
};
