import React from 'react';
import styles from './ClaimPayments.module.less';
import ClaimPaymentsChart from '../../Charts/ClaimPaymentsChart';
import Legend from '../../Charts/Legend';
import { normalizeClaimPaymentsChartData } from '../../utils/normalizeChartData';
import { useTranslation } from 'react-i18next';
import { ClaimPaymentsStatisticDto } from 'types/dto/contracts-service';

interface IClaimPaymentsProps {
  data: ClaimPaymentsStatisticDto[];
}

const ClaimPayments = ({ data: rawData }: IClaimPaymentsProps) => {
  const { t } = useTranslation();

  const chartData = normalizeClaimPaymentsChartData(rawData, t);
  return (
    <div className={styles.small_charts}>
      <p> {t('dash_board.payoff_structure')} %</p>
      <ClaimPaymentsChart className={styles.chart} data={chartData} />
      <div className={styles.chart_legend}>
        <Legend data={chartData} />
      </div>
    </div>
  );
};

export default ClaimPayments;
