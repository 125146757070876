export const APPOINTMENT_TYPES = [
  { id: 1, translationKey: 'appointments.first_visit', name: 'Первинна' },
  { id: 2, translationKey: 'appointments.by_appointment', name: 'За призначенням лікаря' },
];

export const CONTRACT_STATUS_TYPES = [
  { status: 'DRAFT', translationKey: 'appointments.status_draft' },
  { status: 'ACTIVE', translationKey: 'appointments.status_active' },
  { status: 'FINISHED', translationKey: 'appointments.status_finished' },
];

export const MAX_SCROLLBAR_HEIGHT = 350;

export const CLINICS_TYPES = {
  PUBLIC: 'STATE',
  PRIVATE: 'PRIVATE',
};

export const CLINICS_TYPES_DESCRIPTOR = {
  [CLINICS_TYPES.PUBLIC]: 'appointments.public_clinic',
  [CLINICS_TYPES.PRIVATE]: 'appointments.private_clinic',
};
