import React from 'react';
import { Button } from 'antd';
import styles from 'components/tables/InsuredEventsTable/TableTools/TableTools.module.less';
import { ClearIcon, EditIcon } from 'components/Icons';
import { withStyles } from '@material-ui/core';
import mStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface IRowActionsProps<TID = any, TDATA = any> {
  id: TID;
  data: TDATA;
  onDelete: (id: TID, data: TDATA) => any;
  onEdit: (id: TID, data: TDATA) => any;
  onClick: () => any;
  classes: any;
  editable: boolean;
}

const RowActions: React.FC<IRowActionsProps> = ({ id, data, onDelete, onEdit, onClick, classes, editable = true }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} onClick={onClick}>
      {editable && (
        <Button className={styles.button} type="link" onClick={() => onEdit(id, data)}>
          <div className={classes.iconContainer}>
            <EditIcon className={classes.icon} style={{ fontSize: 12 }} />
          </div>
          <span>{t('insurance.edit')}</span>
        </Button>
      )}
      <Button className={styles.button} type="link" onClick={() => onDelete(id, data)}>
        <div className={classes.iconContainer}>
          <ClearIcon className={classes.icon} style={{ fontSize: 12 }} />
        </div>
        <span>{t('insurance.remove')}</span>
      </Button>
    </div>
  );
};

export default withStyles(mStyles)(RowActions);
