import React from 'react';
import { CallEndIcon, CameraIcon, MoreDotsIcon, MuteIcon, ShareScreenIcon } from 'components/Icons';
import classNames from 'classnames';
import styles from './Controls.module.less';
import { Device, MeetingType } from 'types/chats';

interface Props {
  onToggleScreenSharing: () => void;
  onClose: () => void;
  isScreenSharing: boolean;
  type: MeetingType;
  devices: {
    microphone: boolean;
    camera: boolean;
  };
  setDevice(deviceName: Device, state: boolean): void;
  onOpenMenu: () => void;
}

const Controls: React.FC<Props> = ({
  onToggleScreenSharing,
  onClose,
  isScreenSharing,
  devices,
  setDevice,
  type,
  onOpenMenu,
}) => {
  const handleToggleMicrophone = () => {
    setDevice(Device.Microphone, !devices.microphone);
  };

  const handleToggleCamera = () => {
    setDevice(Device.Camera, !devices.camera);
  };

  const handleToggleSharing = () => {
    setDevice(Device.Camera, true);
    onToggleScreenSharing();
  };

  return (
    <div className={styles.controls}>
      <div onClick={handleToggleSharing} className={classNames(styles.icon, isScreenSharing && styles.active)}>
        <ShareScreenIcon color={isScreenSharing ? '#363636' : 'white'} />
      </div>
      <div className={styles.middle}>
        <div
          onClick={handleToggleMicrophone}
          className={classNames(styles.icon, { [styles.active]: !devices.microphone })}
        >
          <MuteIcon color={devices.microphone ? 'white' : '#363636'} />
        </div>
        {type === MeetingType.Video && (
          <div onClick={handleToggleCamera} className={classNames(styles.icon, { [styles.active]: devices.camera })}>
            <CameraIcon color={devices.camera ? '#363636' : 'white'} />
          </div>
        )}
        <div onClick={onClose} className={classNames(styles.icon, styles.close)}>
          <CallEndIcon />
        </div>
      </div>
      <div className={styles.icon} onClick={onOpenMenu}>
        <MoreDotsIcon />
      </div>
    </div>
  );
};

export default Controls;
