import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import SelectIcon from '../../FiltersBar/components/SelectIcon/SelectIcon';
import useMenuStyles from '../../FiltersBar/components/Select/useMenuStyles';
import useSelectStyles from '../styles/useSelectStyles';
import useLabelStyles from '../styles/useLabelStyles';
import useFormControlStyles from '../styles/useFormControlStyles';
import useHelperTextStyles from '../styles/useHelperTextStyles';
import NoData from '../NoData';
import MenuItem from '../MenuItem';

export interface ISelectProps extends Omit<TextFieldProps, 'color'> {
  color?: 'white' | 'gray';
}

const colors = {
  white: '#FFF',
  gray: '#EEEFF4',
};

const Select: React.FC<ISelectProps> = React.forwardRef(
  (
    {
      color = 'gray',
      value,
      InputLabelProps = {},
      InputProps = {},
      FormHelperTextProps = {},
      SelectProps = {},
      children,
      disabled,
      ...props
    },
    ref,
  ) => {
    const menuClasses = useMenuStyles({ color: colors[color], offset: 10 });
    const selectClasses = useSelectStyles({ color: colors[color], height: 56 });
    const labelClasses = useLabelStyles();
    const formControlClasses = useFormControlStyles();
    const helperTextClasses = useHelperTextStyles();

    return (
      <TextField
        {...props}
        classes={formControlClasses}
        variant={'standard'}
        value={value || ''}
        ref={ref as any}
        InputLabelProps={{
          ...InputLabelProps,
          classes: labelClasses,
        }}
        FormHelperTextProps={{
          ...FormHelperTextProps,
          classes: helperTextClasses,
        }}
        InputProps={InputProps}
        SelectProps={{
          ...SelectProps,
          disableUnderline: true,
          disabled: disabled,
          classes: selectClasses,
          IconComponent: SelectIcon,
          MenuProps: {
            classes: menuClasses,
            getContentAnchorEl: null,
            anchorReference: 'anchorEl',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        }}
        select
      >
        {React.Children.count(children) ? (
          children
        ) : (
          <MenuItem>
            <NoData style={{ margin: '0 auto' }} />
          </MenuItem>
        )}
      </TextField>
    );
  },
);

export default Select;
