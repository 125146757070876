export default () => ({
  root: {
    borderRadius: 14,
    backgroundColor: '#eeeff4',
    padding: '4px 16px',
  },
  input: {
    paddingRight: 8,
  },
});
