import { createTypes } from 'redux-compose-reducer';
import * as contractsAPI from 'api/contracts';
import { searchParamsType } from 'types/deviceSearch';
import { notification } from 'antd';
import _ from 'lodash';

export const TYPES = createTypes('deviceSearch', ['setResults', 'clearResults', 'setSearchFilters']);

export const searchContracts = (data: searchParamsType, t) => async (dispatch: any) => {
  const searchParams = {
    ..._.omit(data, ['contractNumber']),
    phoneNumber: data.phoneNumber,
    contractNumber: data.contractNumber,
  };
  dispatch({ type: TYPES.setResults, payload: { pending: true } });
  try {
    const response = await contractsAPI.searchContracts(searchParams);
    const results = response.data ?? [];

    if (results.length === 0) {
      notification.warning({
        message: t('popup.nothing_found'),
        description: t('popup.check_request'),
      });
    }

    dispatch({ type: TYPES.setResults, payload: { pending: false, data: results } });
  } catch (error) {
    dispatch({ type: TYPES.setResults, payload: { pending: false, error: true } });
  }
  return;
};

export const clearSearchResults = () => (dispatch: any) => {
  dispatch({ type: TYPES.clearResults });
};

export const setSearchFields = (searchFields: any) => (dispatch: any) => {
  dispatch({ type: TYPES.setSearchFilters, payload: { searchFields } });
};
