import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { ISublimits } from 'types/sublimits';
import { AnyAction } from 'redux';
import config from 'config';

const initialState = {
  sublimitsData: [],
  sublimitsChildlessData: [],
  subsublimitsData: [],
  sublimitById: {},
  subsublimitById: {},
  pending: false,
  error: null,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'sublimitName',
    direction: 'DESC',
  },
  filters: {
    sublimitName: '',
    subsublimitName: '',
    multiFieldFilter: false,
  },
};

const setPending = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
    error: payload.pending ? null : initialState.error,
  };
};

const setError = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    error: payload ? payload.error : initialState.error,
  };
};

const setSublimits = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    sublimitsData: payload ? payload.sublimits : initialState.sublimitsData,
  };
};

const setSublimitsChildless = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    sublimitsChildlessData: payload ? payload.sublimitsChildless : initialState.sublimitsChildlessData,
  };
};

const setSubsublimits = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    subsublimitsData: payload ? payload.subsublimits : initialState.subsublimitsData,
  };
};

const setSublimitById = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    sublimitById: payload ? payload.sublimit : initialState.sublimitById,
  };
};

const setSubsublimitById = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    subsublimitById: payload ? payload.subsublimit : initialState.subsublimitById,
  };
};

const setSorting = (state: ISublimits, { payload }: AnyAction) => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const setPagination = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    pagination: payload,
  };
};

const setFilters = (state: ISublimits, { payload }: AnyAction) => {
  return {
    ...state,
    filters: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setSublimits,
    setSublimitsChildless,
    setSubsublimits,
    setSublimitById,
    setSubsublimitById,
    setSorting,
    setPagination,
    setFilters,
    setPending,
    setError,
  },
});
