import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { IAppointment } from 'types/appointments';

const initialState = {
  historyData: [],
  sublimitsInfo: [],
  subevent: null,
  claimData: null,
  pending: false,
  error: null,
};

const setPending = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
    error: payload.pending ? null : initialState.error,
  };
};

const setError = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    error: payload ? payload.error : initialState.error,
  };
};

const setHistory = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    historyData: payload ? payload.history : initialState.historyData,
  };
};

const setSublimitsInfo = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    sublimitsInfo: payload ? payload.sublimits : initialState.sublimitsInfo,
  };
};

const setSubevent = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    subevent: payload ? payload.subevent : initialState.subevent,
  };
};

const setClaim = (state: IAppointment, { payload }: AnyAction) => {
  return {
    ...state,
    claimData: payload ? payload.claimData : initialState.claimData,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setHistory,
    setSublimitsInfo,
    setSubevent,
    setClaim,
    setPending,
    setError,
  },
});
