import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import Tooltip from '../Tooltip';
import theme from '../../Theme/index';

interface IClaimPaymentsProps {
  data: any[];
  [x: string]: any;
}

const ClaimPaymentsChart = ({ data, ...props }: IClaimPaymentsProps) => {
  return (
    <div {...props} style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <ResponsivePie
          data={data}
          innerRadius={0.5}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          padAngle={0}
          cornerRadius={0}
          borderWidth={0}
          colors={theme.colors}
          enableRadialLabels={false}
          enableSlicesLabels={false}
          tooltip={Tooltip as any}
          theme={{
            tooltip: {
              container: {
                background: 'transparent',
                boxShadow: 'none',
              },
            },
          }}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
};

export default ClaimPaymentsChart;
