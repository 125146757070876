export const getFranchiseType = (type, value) => {
  switch (type) {
    case 'FIXED_SUM':
      return `${value} ₴`;
    case 'PERCENT':
      return `${value} %`; // %
    case 'NO_VALUE':
      return `-`;
  }
};
