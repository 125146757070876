import { useState, useEffect } from 'react';

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const setOnline = () => {
    setIsOnline(true);
  };

  const setOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.removeEventListener('offline', setOffline);
    return () => {
      window.addEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return isOnline;
};
