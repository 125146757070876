import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  root: {
    '@global': {
      'label + .MuiInput-formControl': {
        margin: '0 !important',
      },
    },
  },
});
