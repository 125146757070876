import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme';

export default makeStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    lineHeight: '18px',
    fontSize: 15,
    color: '#A9B5BD',
    pointerEvents: 'none',
    transform: 'translate(16px, 19px) scale(1) !important',
    userSelect: 'none',
    cursor: 'pointer',
    zIndex: 9,
  },
  error: {
    color: '#EB5757',
  },
  focused: {
    color: '#3A9A3B !important',
  },
  shrink: {
    transform: 'translate(16px, 7px) scale(0.86666666666) !important',
  },
  asterisk: {
    color: '#EB5757',
  },
});
