export default () => ({
  root: {
    fontSize: 15,
    lineHeight: '18px',
    color: '#000000',
  },
  date: {},
  time: {
    color: '#A9B5BD',
  },
  separator: {},
});
