import React from 'react';
import { useTranslation } from 'react-i18next';
import PaginationSizeSelect from './components/PaginationSizeSelect';
import { ItemContainer, Label, Left, LeftItem, Pagination, Right, Root, Value } from './styled';

export interface IPagination {
  size: number;
  page: number;
  total: number;
  disablePerPageChange?: boolean;
}

export interface ITableFooterProps {
  className?: string;
  pagination: IPagination;
  onChange: (pagination: IPagination) => void;
  disablePerPageChange?: boolean;
  dataIdPagination?: string;
  dataIdSize?: string;
  dataIdSizeOptions?: string;
  total?: number;
}

const TableFooter: React.FC<ITableFooterProps> = ({
  pagination,
  onChange,
  className,
  dataIdPagination,
  dataIdSize,
  dataIdSizeOptions,
  disablePerPageChange,
  total,
}) => {
  const { t } = useTranslation();
  const itemCount = total ? total : pagination.total;
  return (
    <Root className={className}>
      {!disablePerPageChange && (
        <Left>
          <LeftItem>
            <ItemContainer>
              <Label> {t('contract_list.found_footer')} </Label>
              <Value>
                {itemCount} {t('contract_list.objects_footer')}
              </Value>
            </ItemContainer>
          </LeftItem>
          <LeftItem>
            <PaginationSizeSelect
              dataId={dataIdSize}
              dataIdOptions={dataIdSizeOptions}
              value={pagination.size}
              onChange={(newSize: number) => onChange({ ...pagination, size: newSize })}
            />
          </LeftItem>
        </Left>
      )}
      <Right>
        <Pagination
          data-id={dataIdPagination}
          count={Math.ceil(itemCount / pagination.size)}
          page={pagination.page}
          onChange={(event: object, page: number) => onChange({ ...pagination, page })}
        />
      </Right>
    </Root>
  );
};
export default TableFooter;
