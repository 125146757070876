import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Grid } from '@material-ui/core';
import brokenRobotSrc from 'assets/img/broken-robot.svg';
import RIPIcon from './RIPIcon';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//@ts-ignore
const useStyles = makeStyles(styles);

export interface IErrorProps {
  code?: string;
  name: string;
  description: string;
}

const Error: React.FC<IErrorProps> = ({ code, name, description }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const onRedirect = () => {
    history.push('/login');
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <div>
        <Grid className={classes.info} justify={'space-between'} container spacing={2} direction={'column'}>
          <Grid item xs={'auto'}>
            <RIPIcon className={classes.ripIcon} />
          </Grid>
          <Grid item>
            {code && <div className={classes.code}>{code}</div>}
            <div className={classes.name}>{name}</div>
            <div className={classes.description}>{description}</div>
            <Button color={'primary'} className={classes.reloadButton} onClick={onRedirect}>
              {t('error_page.reboot')}
            </Button>
          </Grid>
          <Grid item xs={'auto'}>
            <Button type={'button'} className={classes.backLink} onClick={() => history.goBack()}>
              <svg
                className={classes.backLinkIcon}
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14645 1.14646L1.14645 5.14645L1.13459 5.15871C1.12294 5.17118 1.11192 5.18425 1.10158 5.19786L1.14645 5.14645C1.11962 5.17329 1.09647 5.20242 1.07701 5.2332C1.07002 5.24432 1.06329 5.25595 1.05703 5.26787C1.04976 5.28174 1.04336 5.29547 1.03762 5.30942C1.03241 5.32198 1.02756 5.33526 1.02327 5.3488C1.01846 5.36418 1.01441 5.37954 1.0111 5.39503C1.0089 5.40499 1.00703 5.41532 1.00547 5.42576C1.00235 5.44711 1.00057 5.46828 1.00012 5.48948C1.00004 5.49251 1 5.49625 1 5.5L1.00014 5.51156C1.00062 5.53242 1.0024 5.55325 1.00547 5.57393L1 5.5C1 5.5361 1.00383 5.57131 1.01109 5.60523C1.01441 5.62049 1.01846 5.63585 1.02325 5.65103C1.02756 5.66474 1.03241 5.67803 1.03779 5.69104C1.04336 5.70456 1.04976 5.71829 1.05681 5.73178C1.06329 5.74405 1.07002 5.75569 1.07719 5.76702C1.08462 5.77886 1.09278 5.79063 1.10151 5.80211C1.11192 5.81576 1.12294 5.82882 1.13459 5.84129C1.13836 5.84535 1.14236 5.84949 1.14645 5.85358L5.14645 9.85357C5.24407 9.9512 5.37205 10 5.5 10C5.62795 10 5.75593 9.9512 5.85357 9.85357C6.04882 9.6583 6.04882 9.3417 5.85357 9.14645L2.709 6L13.5 6C13.7761 6 14 5.77615 14 5.5C14 5.22385 13.7761 5 13.5 5L2.707 5L5.85357 1.85356C6.04882 1.65828 6.04882 1.34171 5.85357 1.14646C5.6583 0.951181 5.3417 0.951181 5.14645 1.14646Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.4"
                />
              </svg>
              {t('error_page.come_back')}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.pictureContainer}>
        <img className={classes.picture} src={brokenRobotSrc} alt={'broken robot'} />
      </div>
    </div>
  );
};

export default Error;
