export default {
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '60px 80px',
    justifyContent: 'space-between',
  },
  code: {
    fontWeight: 800,
    fontSize: 150,
    lineHeight: '179px',
    color: '#363636',
    margin: '0 -11px',
  },
  name: {
    fontWeight: 'bold',
    fontSize: 44,
    lineHeight: '53px',
    color: '#363636',
    marginTop: -13,
    marginBottom: 16,
  },
  description: {
    fontSize: 13,
    lineHeight: '20px',
    color: '#A9B5BD',
    maxWidth: 256,
    marginBottom: 50,
  },
  info: {
    height: '100%',
    padding: '0 80px',
    // margin: '60px 160px',
  },
  picture: {
    width: '100%',
    display: 'block',
    maxWidth: 766,
    maxHeight: 461,
  },
  pictureContainer: {
    flex: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ripIcon: {
    width: 56,
    height: 56,
  },
  reloadButton: {
    padding: '16px 24px',
  },
  backLink: {
    fontSize: 15,
    lineHeight: '18px',
    color: '#A9B5BD',
    background: 'none',
    '&:hover': {
      background: 'none',
    },
    margin: '-14px -24px',
  },
  backLinkIcon: {
    marginRight: 10,
  },
};
