import { CloseIcon, PopupArrowLeftIcon } from 'components/Icons';
import React, { ReactNode } from 'react';
import Typography from 'components/Typography';
import Button from 'components/Button';
import styles from './Modal.module.less';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Modal: React.FC<{ className?: string }> & {
  Header: React.FC<{
    renderArrow?: boolean;
    onArrowClick?: () => void;
    addonBefore?: ReactNode;
    className?: string;
    title?: string;
    onClose?: () => void;
  }>;
  Body: React.FC<{ className?: string }>;
  Close: React.FC<{ close: () => void }>;
  ArrowBack: React.FC<{ onClick: () => void }>;
  Confirm: React.FC<{ onClick: (value?: any) => void; className?: string; disabled?: boolean }>;
} = ({ children, className }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={classNames(styles.modalContainer, className)}>{children}</div>
    </div>
  );
};

Modal.ArrowBack = ({ onClick }) => (
  <div className={styles.modalArrowOuter} onClick={onClick} onKeyDown={() => {}}>
    <PopupArrowLeftIcon className={styles.modalArrow} />
  </div>
);

Modal.Header = ({ addonBefore, children, className, title, onClose, renderArrow, onArrowClick }) => (
  <header className={classNames(styles.modalHeader, className)}>
    {title ? (
      <>
        {addonBefore}
        {renderArrow && <Modal.ArrowBack onClick={() => onArrowClick && onArrowClick()} />}
        <Typography type="title">{title}</Typography>
        {onClose && <Modal.Close close={onClose} />}
      </>
    ) : (
      children
    )}
  </header>
);

Modal.Body = ({ children, className }) => <div className={classNames(styles.modalBody, className)}>{children}</div>;

Modal.Close = ({ close }) => (
  <div onClick={() => close()} className={styles.modalClose}>
    <CloseIcon />
  </div>
);

const Confirm: React.FC<{
  onClick: (value?: any) => void;
  className?: string;
  disabled: boolean;
}> = ({ className, onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      className={classNames(styles.modalConfirm, className)}
      color="primary"
      onClick={() => onClick()}
    >
      {t('appointments.confirm_variant')}
    </Button>
  );
};

Modal.Confirm = Confirm;

export default Modal;
