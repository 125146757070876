import React, { useEffect, useRef, useState } from 'react';
import styles from './styles';
import { FormControl, withStyles } from '@material-ui/core';
import BaseSelect from '../BaseSelect';
import BaseLabel from '../InputLabel';
import SelectIcon from '../SelectIcon/SelectIcon';
import cs from 'classnames';
import { SelectProps } from '@material-ui/core/Select/Select';
import useMenuStyles from './useMenuStyles';
import ClearButton from '../ClearButton';

export interface ISelectProps extends SelectProps {
  classes?: any;
  label: string;
  value?: any;
  className?: any;
  clearable?: boolean;
  onClear?: () => any;
}

const Select: React.FC<ISelectProps> = ({
  clearable = false,
  classes,
  className,
  value,
  onClear,
  label,
  children,
  ...props
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const menuClasses = useMenuStyles({ offset: 10 });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  useEffect(() => {
    setAnchorEl(rootRef.current!);
  }, []);
  const inputRef = useRef<HTMLInputElement>();
  return (
    <FormControl
      className={cs(classes.root, className, { [classes.clearable]: clearable && value })}
      fullWidth={true}
      ref={rootRef}
    >
      <BaseLabel>{label}</BaseLabel>
      <BaseSelect
        {...props}
        inputRef={inputRef}
        MenuProps={{
          classes: menuClasses,
          getContentAnchorEl: null,
          anchorEl: anchorEl,
          anchorReference: 'anchorEl',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        disableUnderline={true}
        value={value}
        IconComponent={SelectIcon}
      >
        {children}
      </BaseSelect>
      {clearable && value && <ClearButton onClick={onClear} className={classes.clearButton} />}
    </FormControl>
  );
};

//@ts-ignore
export default withStyles(styles)(Select);
