export default () => ({
  root: {
    background: 'none',
  },
  table: {
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    overflow: 'hidden',
  },
  footer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});
