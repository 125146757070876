export default (theme: any) => ({
  root: ({ startIcon }) => ({
    fontFamily: theme.typography.fontFamily,
    borderRadius: 14,
    padding: '14px 24px',
    minWidth: 140,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontWeight: 'normal',
    ...(startIcon
      ? {
          justifyContent: 'flex-start',
          paddingLeft: 46,
          color: '#363636',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#F6F7FA',
            color: '#363636',
          },
          '&:disabled': {
            backgroundColor: '#FFFFFF',
            color: '#A9B5BD',
          },
        }
      : {}),
  }),
  sizeSmall: {
    fontSize: 14,
    lineHeight: '17px',
    padding: '14px 24px 14px',
  },
  sizeLarge: {
    padding: '19px 24px !important',
    lineHeight: '18px',
    fontSize: 15,
  },
  label: {
    lineHeight: '18px',
    fontSize: 14,
  },
  text: {
    backgroundColor: '#F6F8F8',
    color: '#363636',
  },
  textPrimary: {
    backgroundColor: '#3A9A3B',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#43AB44',
    },
    '&:disabled': {
      backgroundColor: '#B2D6B2',
      color: '#FFFFFF',
    },
  },
  textSecondary: {
    backgroundColor: '#EBF5EB',
    color: '#3A9A3B',
    '&:hover': {
      backgroundColor: '#DDF1DD',
    },
    '&:disabled': {
      backgroundColor: '#EBF5EB',
      color: '#B2D6B2',
    },
  },
  startIcon: ({ disabled, iconBackGround }) => {
    return {
      boxSizing: 'border-box',
      backgroundColor: disabled ? 'rgb(237,244,236)' : iconBackGround || '#EBF5EB',
      borderRadius: 10,
      width: 32,
      height: 32,
      marginLeft: 0,
      fontSize: 16,
      padding: 8,
      marginRight: 4,
      position: 'absolute',
      left: 7,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: disabled ? 'rgb(185,212,181)' : '#3A9A3B',
    };
  },
  contained: {
    backgroundColor: '#FFFFFF',
    color: '#3A9A3B',
    '&:hover': {
      backgroundColor: '#F6F7FA',
      color: '#3A9A3B',
    },
    '&:disabled': {
      backgroundColor: '#FFFFFF',
      color: '#B2D6B2',
    },
  },
  containedPrimary: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(149, 155, 159)',
    '&:hover': {
      backgroundColor: '#F6F7FA',
      color: '#3A9A3B',
    },
  },
  containedSecondary: {
    backgroundColor: 'rgb(235,245,235)',
    color: 'rgb(58, 154, 59)',
    '&:hover': {
      backgroundColor: 'rgb(232,245,232)',
    },
  },
  outlinedPrimary: {
    backgroundColor: 'rgb(255, 255, 255)!important',
    border: 'unset',
    '&:hover': {
      border: 'unset',
    },
  },
});
