import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { ViewIcon } from 'components/Icons';

import styles from './TableTools.module.less';

interface ITools {
  record: any;
}

const TableTools = ({ record }: ITools) => {
  const { t } = useTranslation();

  const onEditClick = () => {
    history.push(ROUTES.MEDAPP.SUBLIMITS.DETAILS.ROOT.replace(':id', String(record.id)));
  };

  return (
    <div className={styles.container}>
      <Button className={styles.button} type="link" onClick={onEditClick}>
        <ViewIcon />
        <span>{t('sublimits.edit_subsublimit')}</span>
      </Button>
    </div>
  );
};

export default TableTools;
