import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import BasePagination from '@material-ui/lab/Pagination';
import PaginationItem from 'components/PaginationItem';

const defaultRenderItem = (props: IPaginationProps) => <PaginationItem {...props} />;

// Details: https://material-ui.com/api/pagination/
export interface IPaginationProps {
  boundaryCount?: number;
  children?: React.ReactNode;
  classes?: object;
  color?: 'default' | 'primary' | 'secondary';
  count?: number;
  defaultPage?: number;
  disabled?: boolean;
  getItemAriaLabel?: (type: string, page: number, selected: boolean) => string;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  onChange: (event: object, page: number) => void;
  page: number;
  renderItem?: (params: IPaginationProps) => React.ReactNode;
  shape?: 'round' | 'rounded';
  showFirstButton?: boolean;
  showLastButton?: boolean;
  siblingCount?: number;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined';
}

const Pagination = ({ renderItem = defaultRenderItem, count = 1, ...props }: IPaginationProps) => (
  <BasePagination renderItem={renderItem} count={Math.ceil(count)} {...props} />
);

export default withStyles(styles)(Pagination);
