import { SyntheticEvent } from 'react';
import { IContract, IDetailProgram } from 'types/contractDetails';

export interface IProps {
  form: any;
  contract: IContract;
  handleCheckNumber: (evt: SyntheticEvent) => void;
  currentFormName: string;
  contractDuration: {
    id: number;
    key: number;
    title: string;
    value: number;
  }[];
  contractDetails: IDetailProgram[];
  prefixCheck: string;
  isPurchaseInfoDisabled: boolean;
  isSupplementAgreement?: number | boolean;
  isProlongation: boolean;
  subAgreementNumber: number;
  supplementaryAgreement: boolean;
  isDisabledFields: boolean;
  validateSaleSum: (value: string, field: string) => void;
  getContractCalculation: (data: any) => void;
  insurancePrograms: { id: number; name: string; code?: string; policies?: any }[];
  handleActualSum: (value: string) => void;
  isInternational: boolean;
}

export enum RISK_TYPE {
  APARTMENT = 'APARTMENT',
  PRIVATE_HOUSE = 'PRIVATE_HOUSE',
  COTTAGE = 'COTTAGE',
  TOWN_HOUSE = 'TOWN_HOUSE',
}
