import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { PrivateOfficeState } from '../../types/privateOffice';

const initialState: PrivateOfficeState = {
  userInfo: {
    login: '',
    name: '',
    phone: '',
  },
  data: [],
};

const setPrivateOffice = (state: PrivateOfficeState, { payload }: AnyAction): any => ({ ...payload });

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setPrivateOffice,
  },
});
