import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { AnyAction } from 'redux';
import { IInsuranceConfiguration } from 'types/insuranceConfiguration';
import { initialState as State } from './initialState';

const initialState: IInsuranceConfiguration = State;

const setData = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return { ...state, data: action.payload };
};

const setResolutions = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return { ...state, resolutions: action.payload.data };
};

const setLoading = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return { ...state, loading: action.payload };
};

const setDates = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload,
    },
  };
};

const setLogo = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload,
    },
  };
};

const setCurrency = (state: IInsuranceConfiguration, action: AnyAction): IInsuranceConfiguration => {
  return {
    ...state,
    currency: action.payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setLogo,
    setLoading,
    setDates,
    setResolutions,
    setCurrency,
  },
});
