export default () => ({
  root: {
    borderRadius: 14,
    overflow: 'hidden',
  },
  header: {
    backgroundColor: '#FFFFFF',
    padding: '16px',
    border: 'none',
    fontSize: 20,
    fontWeight: 500,
    color: '#000',
  },
});
