import React from 'react';
import { Button } from 'antd';
import { PrintIcon } from 'components/Icons';
import { checkPermissions } from 'roles/userCan';

import styles from './TableTools.module.less';
import { useTranslation } from 'react-i18next';

interface ITools {
  id: number;
  record?: any;
  handleDownload?: any;
}

const TableTools = ({ record, handleDownload }: ITools) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {checkPermissions('TERMINATION', 'PRINT') ? (
        <Button className={styles.button} type="link" onClick={() => handleDownload(record)}>
          <PrintIcon />
          <span>{t('contract_details.print_button_terminations')}</span>
        </Button>
      ) : (
        <span>{t('contract_details.no_actions_available_terminations')}</span>
      )}
    </div>
  );
};

export default TableTools;
