import React from 'react';
import Typography from 'components/Typography';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import styles from './WarningPopup.module.less';

interface WarningPopupProps {
  width: number;
  height: number;
  icon(): JSX.Element;
  title: string;
  body: string;
  onClose(): void;
}

const WarningPopup: React.FC<WarningPopupProps> = ({ icon, title, body, onClose, width, height }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.overlay} style={{ width, height }}>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className={styles.popup}>
          <div className={styles.popupData}>
            {icon()}
            <Typography type="title">{title}</Typography>
            <Typography type="p2">{body}</Typography>
          </div>
          <div className={styles.close} onClick={onClose}>
            <Typography type="title">{t('chats.confirm')}</Typography>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default WarningPopup;
