import { AppState } from 'store/reducer';

export const paymentsSelector = ({
  contractDetails: {
    paymentsList: { data, statistic, loading, pagination, total },
  },
}: AppState) => ({
  data,
  pagination,
  loading,
  total,
  statistic,
});
