import { useEffect, useState } from 'react';
import { IUseDataState } from 'types/hooks';

const useData = <T>(request: () => T | Promise<T>, deps: any[] = []): IUseDataState<T> => {
  const [state, setState] = useState<IUseDataState<T>>({
    data: undefined,
    error: undefined,
    loading: false,
  });

  useEffect(() => {
    (async () => {
      setState({
        loading: true,
        error: undefined,
        data: undefined,
      });
      try {
        setState({
          error: undefined,
          data: await request(),
          loading: false,
        });
      } catch (e) {
        setState({
          data: undefined,
          error: e,
          loading: false,
        });
      }
    })();
  }, deps);

  return state;
};

export default useData;
