import { useEffect, useRef, useState } from 'react';
import { useOnResize } from './useOnResize';

export const useDOMSize = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  const [size, setSize] = useState<DOMRect>();

  useEffect(() => {
    if (ref.current) {
      const nextTick = setTimeout(() => {
        setSize(ref.current?.getBoundingClientRect());
      }, 0);
      return () => clearTimeout(nextTick);
    }
  }, [ref]);

  useOnResize(() => {
    if (ref.current) {
      setSize(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  return { size, ref };
};
