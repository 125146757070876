import React, { useState } from 'react';
import Typography from 'components/Typography';
import { ChevronRightIcon, GeotagIcon, Check, Dollar, PhoneCall } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import { IClinic } from 'types/institutions';
import { CLINICS_TYPES_DESCRIPTOR } from 'constants/appointments';
import styles from './Clinic.module.less';

type ClinicProps = IClinic & {
  isPicked: boolean;
};

export const Clinic: React.FC<ClinicProps> = ({ isPicked, ...clinic }) => {
  const { legalName, city, street, coverage, isOnline, propertyType } = clinic;

  const { t } = useTranslation();

  const [isMoreOpened, setIsMoreOpened] = useState(false);

  const handleClick = () => {
    setIsMoreOpened(true);
  };

  return (
    <div onClick={handleClick} className={styles.clinic} role="presentation">
      <div className={styles.clinicAbbr}>
        <div className={styles.clinicNameAndTag}>
          <Typography type="p2">{legalName}</Typography>
          <div className={styles.location}>
            <GeotagIcon />
            <span className={styles.locationName}>
              м. {city}, {street}
            </span>
          </div>
        </div>
        {isPicked ? <Check width={15} height={13} /> : <ChevronRightIcon className={styles.chevronRight} />}
      </div>
      {isPicked && (
        <div className={styles.clinicMore}>
          {coverage && (
            <div className={styles.moreItemWrapper}>
              <div className={styles.iconWrapper}>
                <Dollar />
              </div>
              <span>
                {t('appointments.clinic_coverage')}: <span className={styles.coverage}>{Number(coverage) * 100}%</span>
              </span>
            </div>
          )}
          {isOnline && (
            <div className={styles.moreItemWrapper}>
              <div className={styles.iconWrapper}>
                <PhoneCall phoneColor="#EBF5EB" />
              </div>
              <span>{t('appointments.clinic_available_online')}</span>
            </div>
          )}
          <span className={styles.clinicBadge}>{t(CLINICS_TYPES_DESCRIPTOR[propertyType])}</span>
        </div>
      )}
    </div>
  );
};
