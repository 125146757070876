import { makeStyles } from '@material-ui/core/styles';
// import theme from '../../../styles/theme';

export interface IUseSelectStylesProps {
  color?: string;
  activeColor?: string;
}

export default makeStyles({
  root: {
    boxSizing: 'border-box',
    height: 56,
    borderRadius: 14,
    paddingRight: '44px !important',
    backgroundColor: (props: IUseSelectStylesProps) => props.color,
    padding: '28px 32px 10px 16px',
    minWidth: 140,
    color: '#363636',
    fontSize: 15,
    '&:focus, &:hover, &:active': {
      borderRadius: 14,
      backgroundColor: (props: IUseSelectStylesProps) => props.color + ' !important',
    },
  },
  // error: {
  //   backgroundColor: theme.pallete.table.red.light,
  // },
  icon: {
    top: 19,
  },
  disabled: {
    cursor: 'not-allowed !important',
  },
});
