import React from 'react';
import { moneyFormatter } from 'utils/helpers';

export const MiniColumns = (t: any, currency: string) => {
  return [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      width: 50,
    },
    {
      title: t('contract_details.insurance_object_jutlo_details'),
      dataIndex: 'name',
      key: 'mini_name',
    },
    {
      title: t('contract_details.insurance_sum_jutlo_details'),
      dataIndex: 'insuranceSumForObject',
      key: 'insuranceSumForObject',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.insurance_rate_jutlo_details'),
      dataIndex: 'insuranceRate',
      key: 'insuranceRate',
      width: 200,
      render: (text: number) => <span>{text ? `${(text * 100).toFixed(3)} %` : '0.00 %'}</span>,
    },
    {
      title: t('contract_details.payment_amount_jutlo_details'),
      dataIndex: 'paymentAmountForObject',
      key: 'paymentAmountForObject',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.paid_pl_jutlo_details'),
      dataIndex: 'paymentAmountForObject',
      key: 'paymentAmount',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.franchise_jutlo_details'),
      dataIndex: 'franchise',
      key: 'mini_franchise',
      width: 200,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
  ];
};

export const ExpandedColumns = (t, currency) => {
  return [
    {
      title: '№',
      dataIndex: 'index',
      key: 'index',
      width: 30,
    },
    {
      title: t('contract_details.insurance_risks_jutlo_details'),
      dataIndex: 'name',
      width: 550,
    },
    {
      title: t('contract_details.insurance_amount_jutlo_details'),
      dataIndex: 'insuranceAmount',
      width: 100,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.payment_amount_expanded_jutlo_details'),
      dataIndex: 'paymentAmount',
      width: 90,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
    {
      title: t('contract_details.franchise_expanded_jutlo_details'),
      dataIndex: 'franchise',
      width: 90,
      render: (text: number) => <span>{`${moneyFormatter(text)} ${currency}`}</span>,
    },
  ];
};
