import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';

export default makeStyles({
  intentNone: {},
  intentSuccess: {
    backgroundColor: theme.pallete.primary.lightest + ' !important',
    color: theme.pallete.primary.main + ' !important',
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
  intentDanger: {
    backgroundColor: theme.pallete.table.red.light + ' !important',
    color: theme.pallete.table.red.main + ' !important',
  },
  intentWarn: {
    backgroundColor: theme.pallete.table.yellow.light + ' !important',
    color: theme.pallete.table.yellow.main + ' !important',
  },
  intentBlack: {
    color: '#363636 !important',
  },
});
