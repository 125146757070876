export const initialState = {
  phoneNumber: '',
  token: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export const initialActiveState = {
  phoneNumber: true,
  email: false,
  token: false,
  password: false,
  confirmPassword: false,
};
