import React from 'react';
import { Chip as ChipBase, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';

const useStyles = makeStyles({
  default: {
    color: '#F2994A !important',
    backgroundColor: '#FFFFFF !important',
  },
  success: {
    color: '#3A9A3B !important',
    backgroundColor: 'rgba(58,154,59, 0.1) !important',
  },
  warn: {
    color: '#F2C94C !important',
    backgroundColor: 'rgba(242,201,76, 0.15) !important',
  },
  danger: {
    color: '#EB5757 !important',
    backgroundColor: 'rgba(235,87,87, 0.1) !important',
  },
  yellow: {
    color: '#F2994A !important',
    backgroundColor: 'rgba(253, 240, 228, 1) !important',
  },
  red: {
    color: '#E2353B !important',
    backgroundColor: 'rgba(251, 225, 226, 1) !important',
  },
  blue: {
    color: '#47A5EA !important',
    backgroundColor: 'rgba(227, 242, 252, 1) !important',
  },
  green: {
    color: '#459E3C !important',
    backgroundColor: 'rgba(227, 241, 226, 1) !important',
  },
});

export interface IChipProps {
  color?: 'default' | 'success' | 'warn' | 'danger' | 'yellow' | 'red' | 'blue' | 'green';
  label: string;
  className?: string;
}

const Chip: React.FC<IChipProps> = ({ className, color = 'default', ...props }) => {
  const colorClasses = useStyles();

  return <ChipBase {...props} className={cs(className, colorClasses[color])} />;
};

export default withStyles({
  root: {
    height: 30,
    borderRadius: 10,
  },
  label: {
    padding: '6px 10px 7px',
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 'normal',
  },
})(Chip);
