import { useState } from 'react';
import { cloneDeep } from 'lodash/fp';

export const useModal = <T>(onClose?: (data?: T) => void, given?: T) => {
  const [isOpen, setIsOpen] = useState(false);

  const [picked, setPicked] = useState(cloneDeep(given));

  const open = () => {
    setIsOpen(true);
  };

  const closeWith = (value?: T) => () => {
    if (onClose) {
      onClose(value);
    }
    setIsOpen(false);
  };

  return {
    isOpen,
    open,
    close: closeWith(given),
    confirm: closeWith(picked),
    picked,
    onPick: setPicked,
  };
};
