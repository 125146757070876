import { withStyles } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import BaseMenu from '@material-ui/core/Menu';

export const Trigger = withStyles({
  root: {
    cursor: 'pointer',
    padding: '8px 16px 9px',
  },
})(Box);

export const TriigerIcon = withStyles({
  root: {
    display: 'block',
    color: '#A9B5BD',
    transition: 'all 0.3s',
  },
})(KeyboardArrowDown);

export const Menu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.12)',
    borderRadius: '0 0 12px 12px',
    width: 191,
  },
  list: {
    padding: 0,
  },
})(BaseMenu);
