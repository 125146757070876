import React from 'react';
import { css } from '@emotion/css';

import logo from 'assets/img/logo.png';
import logoCHI from 'assets/img/logo_chi.svg';

const cssLogos = css`
  display: flex;
  align-items: center;
  margin-bottom: 90px;
`;

const cssLogo = css`
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edf6ed;
  border-radius: 16px;
  margin-right: 95px;

  img {
    width: 85%;
  }
`;

const Header = () => (
  <div className={cssLogos}>
    <div className={cssLogo}>
      <img src={logo} alt="Logo" />
    </div>
    <img src={logoCHI} alt="Logo CHI" />
  </div>
);

export default Header;
