import { Box, Typography, withStyles } from '@material-ui/core';

export const Root = withStyles({
  root: {
    padding: '7px 10px',
    background: '#ffffff',
    boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
  },
})(Box);

export const Title = withStyles({
  body1: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    color: '#fb8068',
    marginBottom: 4,
  },
})(Typography);

export const Body = withStyles({
  root: {
    display: 'flex',
  },
})(Box);

export const Value = withStyles({
  body1: {
    fontSize: 15,
    lineHeight: '18px',
    color: '#363636',
    margin: '0 2px 0 0',
  },
})(Typography);

export const Delta = withStyles({
  body1: {
    fontSize: 10,
    lineHeight: '12px',
    color: '#3A9A3B',
  },
  colorError: {
    color: '#EB5757',
  },
})(Typography);

export const Sum = withStyles({
  body1: {
    marginTop: 5,
    fontSize: 14,
    lineHeight: '16px',
  },
})(Typography);
