import { useEffect } from 'react';
import createInstance from './axios';
import { useAsync } from 'hooks/useAsync';
import { useHistory } from 'react-router-dom';

const appointmentApi = createInstance('APPOINTMENT');

const getChannelInfoById = async (id: string) => {
  const { data } = await appointmentApi.get(`/appointment/channel/${id}`);
  return data;
};

export const useChannelInfoById = () => {
  const history = useHistory();
  const channelId = history.location.pathname.split('/')[2];

  const { value: data, doFetch: getChannel } = useAsync(getChannelInfoById);

  useEffect(() => {
    getChannel(channelId);
  }, []);

  const calculationDuration = (start: string, end: string) => {
    if (!start && !end) {
      return 0;
    }
    const strArr = start.split(':');
    const endArr = end.split(':');

    const strMin = Number(strArr[0]) * 60 + Number(strArr[1]);
    const endMin = Number(endArr[0]) * 60 + Number(endArr[1]);

    return endMin - strMin;
  };

  const formatedData = {
    ...data,
    duration: calculationDuration(data?.dateSlot?.time_from, data?.dateSlot?.time_to),
  };

  return { data: data ? formatedData : null, history, channelId };
};
