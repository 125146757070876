import React from 'react';
import cls from 'classnames';
import { ChevronRightIcon } from 'components/Icons';
import Status from '../Status';
import { IActiveStatus } from 'types/appointments';
import styles from './Previewer.module.less';

interface PreviewerProps {
  title: string;
  displayValue: any;
  onClick(): void;
  hideCheckbox?: boolean;
  looksLikeSelect?: boolean;
  status?: IActiveStatus | null;
  errorMessage?: string;
  disabled?: boolean;
}

const Previewer: React.FC<PreviewerProps> = ({
  title,
  displayValue,
  onClick,
  hideCheckbox,
  looksLikeSelect,
  status,
  errorMessage,
  disabled,
}) => {
  return (
    <div
      className={cls(styles.previewer, {
        [styles.like_select]: looksLikeSelect,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? () => {} : onClick}
    >
      {!hideCheckbox && <input type="checkbox" className={styles.checkbox} checked={!!displayValue} />}
      <div className={styles.text}>
        <span className={cls(styles.label, { [styles.error]: errorMessage })}>{title}</span>
        {displayValue && <span>{displayValue}</span>}
        {errorMessage && <span className={styles.error_text}>{errorMessage}</span>}
      </div>
      {status && (
        <div className={styles.status_wrapper}>
          <Status status={status.statusType} translation={status.translation} />
        </div>
      )}
      <ChevronRightIcon className={styles.chevronRight} />
    </div>
  );
};

export default Previewer;
