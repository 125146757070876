import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { IInsuredEvent } from 'types/InsuredEvent';
import { Action, IPageStore } from '../../types/store';
import { InsuranceProgram } from '../../types/insuranceProgram';
import { IInsuranceProgram } from '../../types/insuranceProgram';
import { AnyAction } from 'redux';

interface IProgramState extends IPageStore<IInsuranceProgram> {}

const initialState: IProgramState = {
  data: new InsuranceProgram(),
  loading: false,
};

const setProgramInfo = (state: any, { payload }: Action<IInsuredEvent>) => {
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  };
};

const clearProgramInfo = (state: any) => {
  return {
    ...state,
    data: initialState.data,
  };
};

const setLoading = (state: any, { payload }: Action<boolean>) => ({
  ...state,
  loading: payload,
});

const setPartners = (state: any, { payload }: Action<AnyAction>) => ({
  ...state,
  data: {
    ...state.data,
    programPartners: payload,
  },
});

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setLoading,
    setProgramInfo,
    clearProgramInfo,
    setPartners,
  },
});
