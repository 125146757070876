import React from 'react';
import { Root, Title, Body, Value, Delta, Sum } from './Tooltip.styled';
import numeral from 'numeral';

const Tooltip = ({ id, label, value, sum, previousValue, fullValue, partFromAll }: any) => {
  const deltaSign = value >= previousValue ? '+' : '';
  let delta = ((value - previousValue) / value) * 100;
  if (partFromAll) delta = fullValue > 1 ? 100 - ((fullValue - value) / fullValue) * 100 : 100;
  const deltaFormatted = numeral(delta).format('0.[00]');

  return (
    <Root>
      <Title>{label || id}</Title>
      <Body>
        <Value>{value}</Value>
        {(partFromAll && <Delta color={'initial'}>{`${deltaFormatted}%`}</Delta>) ||
          (value !== previousValue && (
            <Delta color={value < previousValue ? 'error' : 'initial'}>{`${deltaSign}${deltaFormatted}%`}</Delta>
          ))}
      </Body>
      {sum && <Sum>{`(${sum} грн)`}</Sum>}
    </Root>
  );
};

export default Tooltip;
