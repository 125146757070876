import { TranferHistoriesData } from 'components/tables/ContractDetailsTables/ImportExportTable/ImportExportTable';
import moment from 'moment';

export const importExportDataFormatter = (dataArr: TranferHistoriesData[], t) => {
  let type = '';
  return dataArr
    ?.reduce((acc: TranferHistoriesData[] = [], curr: TranferHistoriesData) => {
      if (curr) {
        if (curr?.contractTransferType?.toUpperCase() === 'IMPORT_VISIT_UKRAINE') {
          type = 'IMPORT_VISIT_UKRAINE';
        } else {
          type = 'EXPORT_1C';
        }

        curr.success = {
          status: curr.success,
          type: type,
        };

        let importType = type === 'IMPORT_VISIT_UKRAINE';

        if (curr.message === 'NOT LOADED') {
          curr.message = t('contract_details.not_loaded_import_export');
        }
        if (curr.message?.includes(': LOADED')) {
          curr.message = '';
        }
        if (curr.message?.toLowerCase() === 'required') {
          curr.message = t('contract_details.fields_not_filled_correctly_import_export');
        }
        if (curr.message?.toLowerCase() === 'invalid_length') {
          curr.message = t('contract_details.incorrect_length_import_export');
        }
        if (curr.message?.toLowerCase() === 'duplication') {
          curr.message = `${t('contract_details.contract_import_export')} ${
            importType ? t('contract_details.import_import_export') : t('contract_details.export_import_export')
          } ${t('contract_details.earlier_import_export')}`;
        }
        if (!curr?.message) {
          curr.message = ``;
        }

        acc.push(curr);
      }

      return acc;
    }, [])
    ?.sort((left, right): any => moment.utc(left.operationDate).diff(moment.utc(right.operationDate)))
    ?.reverse();
};
