import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Tooltip from '../Tooltip';
import { Root, Container } from './SoldChart.styled';
import ChartBar from './ChartBar/index';
import theme from '../../Theme/index';
import { maxTickValue } from 'utils/helpers';

const SoldChart = ({ data, ...props }: { data: any[]; [x: string]: any }) => {
  return (
    <Root {...props}>
      <Container>
        <ResponsiveBar
          data={data}
          margin={{ top: 10, right: -24, bottom: 20, left: 30 }}
          keys={['value']}
          indexBy="label"
          borderWidth={0}
          colors={theme.colors}
          borderRadius={14}
          barComponent={ChartBar}
          enableLabel={false}
          tooltip={Tooltip as any}
          theme={{
            tooltip: {
              container: {
                background: 'transparent',
                boxShadow: 'none',
              },
            },
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          axisLeft={{
            tickValues: maxTickValue(data),
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 0,
          }}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </Container>
    </Root>
  );
};

export default SoldChart;
