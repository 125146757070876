import CreateInstance from './axios';

const proxyApi = CreateInstance('PROXY');

export const getClaims = async ({ claimStatus }) => {
  const requestParams = {
    claimStatus,
    count: true,

    // Add sorting, when user is not admin
    // sorting: 'startDate',

    // Attributes for a specific field
    // attributes: 'claimStatus',
  };

  const { data } = await proxyApi.get('/claims', {
    params: {
      ...requestParams,
    },
  });
  return data;
};
