import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import { deviceSearchType } from 'types/deviceSearch';

const initialState: deviceSearchType = {
  data: [],
  error: false,
  pending: false,
  searchFields: {
    identifier: '',
    contractNumber: '',
    phoneNumber: '',
  },
};

const setResults = (state: deviceSearchType, { payload }: AnyAction): deviceSearchType => {
  return {
    ...state,
    data: payload.data || initialState.data,
    pending: payload.pending || initialState.pending,
    error: payload.error || initialState.error,
  };
};

const clearResults = () => {
  return initialState;
};

const setSearchFilters = (state: deviceSearchType, { payload }: AnyAction): deviceSearchType => {
  return {
    ...state,
    searchFields: payload.searchFields || state.searchFields,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setResults,
    clearResults,
    setSearchFilters,
  },
});
